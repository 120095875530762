import { ActivityEventSchema } from '@formo/types';
import { addDays, addMonths, addYears } from '@formo/ui';
import { ShieldQuestion } from 'lucide-react';
import { ReactNode } from 'react';
import ReactCountryFlag from 'react-country-flag';
import {
  EventActionConnectWallet,
  EventActionDisconnectWallet,
  EventActionIdentify,
  EventActionPageHit,
  EventActionSignature,
  EventActionTransaction,
  EventDeviceBot,
  EventDeviceDesktop,
  EventDeviceMobileAndroid,
  EventDeviceMobileIOS,
} from '~/components/icons/analytics';
import countryNameJSON from '~/json/country.name.json';

import { getChain } from '../chains';
import { WALLET_MAP } from '../wallets';

// ACTIONS
export enum EVENT_ACTION {
  PAGE_HIT = 'page_hit',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  SIGNATURE = 'signature',
  CHAIN_CHANGED = 'chain_changed',
  IDENTIFY = 'identify',
  DECODED_LOG = 'decoded_log',
  TRANSACTION = 'transaction',
}

const renderWalletName = (providerName?: string): string => {
  if (!providerName) return '';

  return ` (${providerName})`;
};

const renderWalletIcon = (rdns?: string): ReactNode => {
  if (!rdns) return <EventActionIdentify />;

  if (!(rdns in WALLET_MAP)) return <ShieldQuestion size={16} />;

  return (
    <span className="[&>svg]:w-4 [&>svg]:h-4">
      {WALLET_MAP[rdns as keyof typeof WALLET_MAP]?.icon}
    </span>
  );
};

export const renderChainName = (chainId?: string | number): string => {
  if (!chainId) return '';

  const chain = Number(chainId);
  if (!chain) return ` (Unknown chain id ${chainId})`;
  const extractedChain = getChain(chain);
  if (!extractedChain) return ` (Unknown chain id ${chainId})`;
  return ` (${extractedChain.name})`;
};

export const EVENT_ACTION_KEYS = Object.values(EVENT_ACTION);
export const EVENT_ACTION_TEXT_SHORT: Record<EVENT_ACTION, string> = {
  [EVENT_ACTION.PAGE_HIT]: 'Page hit',
  [EVENT_ACTION.CONNECT]: 'Wallet connect',
  [EVENT_ACTION.DISCONNECT]: 'Wallet disconnect',
  [EVENT_ACTION.SIGNATURE]: 'Signature',
  [EVENT_ACTION.CHAIN_CHANGED]: 'Chain changed',
  [EVENT_ACTION.TRANSACTION]: 'Transaction',
  [EVENT_ACTION.IDENTIFY]: 'Identify wallet',
  [EVENT_ACTION.DECODED_LOG]: 'Decoded log',
};

export const EVENT_ACTION_TEXT: Record<
  EVENT_ACTION | string,
  (record: Partial<ActivityEventSchema>) => ReactNode
> = {
  [EVENT_ACTION.PAGE_HIT]: (record) => {
    const path = record?.event_payload?.pathname;
    if (path === '/') return 'Viewed homepage';
    const href = record?.event_payload?.href?.split(/https?:\/\//)?.[1];
    return 'Viewed ' + (href || '');
  },
  [EVENT_ACTION.CONNECT]: (record) =>
    `Connected wallet${renderChainName(record?.event_payload?.chain_id)}`,
  [EVENT_ACTION.DISCONNECT]: (record) =>
    `Disconnected wallet${renderChainName(record?.event_payload?.chain_id)}`,
  [EVENT_ACTION.SIGNATURE]: (record) =>
    `Signature ${record?.event_payload?.status}${renderChainName(record?.event_payload?.chain_id || record?.event_payload?.chainId)}`,
  [EVENT_ACTION.CHAIN_CHANGED]: (record) =>
    `Chain changed${renderChainName(record?.event_payload?.chain_id || record?.event_payload?.chainId)}`,
  [EVENT_ACTION.TRANSACTION]: (record) =>
    `Transaction ${record?.event_payload?.status}${renderChainName(record?.event_payload?.chain_id || record?.event_payload?.chainId)}`,
  [EVENT_ACTION.IDENTIFY]: (record) =>
    `Identified wallet${renderWalletName(record?.event_payload?.provider_name || record?.event_payload?.name)}`,
  [EVENT_ACTION.DECODED_LOG]: () => 'Decoded log',
};

export const EVENT_ACTION_ICON: Record<
  EVENT_ACTION | string,
  (record?: Partial<ActivityEventSchema>) => ReactNode
> = {
  [EVENT_ACTION.PAGE_HIT]: () => <EventActionPageHit />,
  [EVENT_ACTION.CONNECT]: () => <EventActionConnectWallet />,
  [EVENT_ACTION.DISCONNECT]: () => <EventActionDisconnectWallet />,
  [EVENT_ACTION.SIGNATURE]: () => <EventActionSignature />,
  [EVENT_ACTION.CHAIN_CHANGED]: () => <EventActionPageHit />,
  [EVENT_ACTION.TRANSACTION]: () => <EventActionTransaction />,
  [EVENT_ACTION.IDENTIFY]: (record) =>
    renderWalletIcon(record?.event_payload?.rdns),
  [EVENT_ACTION.DECODED_LOG]: () => <EventActionPageHit />,
};

// DEVICES
export enum EVENT_DEVICE {
  DESKTOP = 'desktop',
  MOBILE_ANDROID = 'mobile-android',
  MOBILE_IOS = 'mobile-ios',
  BOT = 'bot',
}

export const EVENT_DEVICE_KEYS = Object.values(EVENT_DEVICE);
export const EVENT_DEVICE_TEXT_SHORT: Record<EVENT_DEVICE, string> = {
  [EVENT_DEVICE.DESKTOP]: 'Desktop',
  [EVENT_DEVICE.MOBILE_ANDROID]: 'Android',
  [EVENT_DEVICE.MOBILE_IOS]: 'iOS',
  [EVENT_DEVICE.BOT]: 'Bot',
};
export const EVENT_DEVICE_TEXT: Record<EVENT_DEVICE | string, React.ReactNode> =
  {
    [EVENT_DEVICE.DESKTOP]: <span>Desktop</span>,
    [EVENT_DEVICE.MOBILE_ANDROID]: <span>Android</span>,
    [EVENT_DEVICE.MOBILE_IOS]: <span>iOS</span>,
    [EVENT_DEVICE.BOT]: <span className="text-content-danger">Bot</span>,
  };

export const EVENT_DEVICE_ICON: Record<EVENT_DEVICE | string, React.ReactNode> =
  {
    [EVENT_DEVICE.DESKTOP]: <EventDeviceDesktop />,
    [EVENT_DEVICE.MOBILE_ANDROID]: <EventDeviceMobileAndroid />,
    [EVENT_DEVICE.MOBILE_IOS]: <EventDeviceMobileIOS />,
    [EVENT_DEVICE.BOT]: <EventDeviceBot className="text-content-danger" />,
  };

// COUNTRIES
export const COUNTRY_CODES = Object.keys(countryNameJSON);
export const EVENT_COUNTRY_TEXT: Record<string, string> = countryNameJSON;

export const EVENT_COUNTRY_ICON = ({
  code,
  size = 20,
}: {
  code: string;
  size?: number;
}) => {
  return (
    <ReactCountryFlag
      countryCode={code}
      svg
      width={size}
      height={size}
      style={{
        width: size + 'px',
        height: size + 'px',
      }}
      className="rounded-full object-cover"
    />
  );
};

// DATE RANGE FILTER
export const DATE_RANGE_FILTER = [
  {
    label: 'Today',
    date: {
      from: new Date(),
      to: new Date(),
    },
  },
  {
    label: 'Last 7 days',
    date: {
      from: addDays(new Date(), -7),
      to: new Date(),
    },
  },
  {
    label: 'Last 30 days',
    date: {
      from: addDays(new Date(), -30),
      to: new Date(),
    },
  },
  {
    label: 'Last 3 months',
    date: {
      from: addMonths(new Date(), -3),
      to: new Date(),
    },
  },
  {
    label: 'Last 1 year',
    date: {
      from: addYears(new Date(), -1),
      to: new Date(),
    },
  },
];

export const PROJECT_READ_TOKEN_KEY_NAME = (projectId: string) =>
  projectId + '_rtk';

export const LAST_ACTIVE_PROJECT_KEY_NAME = 'last_active_project';
