import { FC, HTMLAttributes } from 'react';

type EventDeviceMobileAndroidProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const EventDeviceMobileAndroid: FC<EventDeviceMobileAndroidProps> = ({
  size = 20,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      {...props}
    >
      <path
        stroke={color}
        strokeWidth={3}
        d="m128 97.76a64.54 64.54 0 0 0 -32.8-48.68l10.8-18.3a2 2 0 0 0 -3.45-2l-10.92 18.47a64.33 64.33 0 0 0 -55.32 0l-10.88-18.5a2 2 0 0 0 -3.45 2l10.77 18.3a64.36 64.36 0 0 0 -32.75 48.71 2.08 2.08 0 0 0 .5 1.57 2 2 0 0 0 1.5.67h124a2 2 0 0 0 2-2.24zm-123.71-1.76a60.45 60.45 0 0 1 119.41 0z"
      />
      <path
        stroke={color}
        strokeWidth={3}
        d="m34 70a6 6 0 1 0 6 6 6 6 0 0 0 -6-6zm0 8a2 2 0 1 1 2-2 2 2 0 0 1 -2 2z"
      />
      <path
        stroke={color}
        strokeWidth={3}
        d="m94 70a6 6 0 1 0 6 6 6 6 0 0 0 -6-6zm0 8a2 2 0 1 1 2-2 2 2 0 0 1 -2 2z"
      />
    </svg>
  );
};

export default EventDeviceMobileAndroid;
